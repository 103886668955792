// @ts-strict-ignore
/* eslint-disable */
(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
    v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://content.analytics.informedk12.com/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

        // Call this whenever information about your visitors becomes available
        // Please use Strings, Numbers, or Bools for value types.
        if (window.jsData.userTraits) { // if user is signed in
          const sfVisitorId = { sfVisitorId : window.jsData.userTraits.id };
          const userTraits = Object.assign({}, window.jsData.userTraits, sfVisitorId);

          window.pendo.initialize({
              visitor: userTraits,
              account: window.jsData.userOrganizationTraits,
            });
          } else {
            window.pendo.initialize({ visitor: 'VISITOR-UNIQUE-ID'});
        }
})(window.jsData.pendoAPIKey);

